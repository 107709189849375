.product-summary {
  width: 100%;
  .info-summary {
    display: flex;
    flex-wrap: wrap;
  }
}

.card {
  border: 1px solid #ccc;
  // border-bottom: 3px solid var(--light-blue);
  padding: 5px;
  background-color: #f5f6fa;
}

.card1 {
  background-color: #7D1935;
}
.card2 {
  background-color: #A0C49D;
}
.card3 {
  background-color: #0B2447;
}
.card4 {
  background-color: #435055;
}
